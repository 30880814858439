import React from 'react';
import Logo_light from './logo_light.svg';
import Logo_dark from './logo_dark.svg';
import { Link } from 'react-router-dom';


const Header = ({theme}) => {
  return (
    <>
      <div className="header-container" style={{position: "fixed", width: "100%", top: 0, zIndex: 1000}}>
        <div className="header">
          <Link to="/" style={{textDecoration: "none", color: "black", display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "5%"}}>
              <img src={theme === 'light' ?  Logo_light : Logo_dark} alt="Logo" style={{height: "35px"}} />
              <h1 style={{color: theme === 'light' ? 'black' : 'white'}}>inbetween</h1>
          </Link>
        </div>
      </div>
      <div className='header-background' style={{position: "fixed", height: "100px", width: "100%", backdropFilter: "blur(10px)", top: 0, zIndex: 999}}>
      </div>
    </>
  );
};

export default Header;