import React from 'react';
import star from './star.circle.fill.svg';
import './App.css';

class Monthly extends React.Component {
  constructor(props) {
    super(props);
    const today = new Date();
    this.state = {
      month: today.getMonth(),
      year: today.getFullYear()
    };
  }

  render() {
    const { month, year } = this.state;
    const monthNames = [
        "january", "february", "march", "april", "may", "june",
        "july", "august", "september", "october", "november", "december"
    ];

    return (
        <a href="https://inbetweenplaces.app/monthly-event/">
        <div className='monthly'
        style={{
            
            }}>
                
        <img src={star} alt="star" height='25px' style={{marginRight: '5px'}}/>
        <h1 type="banner">
          {monthNames[month]} editor's pick is now live
        </h1>
      </div>
      </a>
    );
  }
}

export default Monthly;