import hero_light from './Group 6.png';
import hero_dark from './Group 9.png';
import logo_light from './logo_light.svg';
import logo_dark from './logo_dark.svg';
import './App.css';
import { Link } from 'react-router-dom';
import AppStore from './Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import { useTheme } from './themeContext'; 
import React, { useEffect } from 'react';
import sun from './sun.svg';
import moon from './moon.svg';
import Monthly from './Monthly.jsx';



function App() {
  const { theme, toggleTheme } = useTheme();

  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", theme === 'light' ? '#f6f6f6' : '#141414');
  }, [theme]);

  const handleToggleTheme = () => {
    const icon = document.querySelector('.button-img');
    const heroImage = document.querySelector('.App-hero'); // Select the hero image
    icon.classList.add('blur');
    heroImage.classList.add('blur'); // Add blur to hero image
    setTimeout(() => {
      toggleTheme();
      document.documentElement.classList.toggle('dark-theme', theme === 'light');
      setTimeout(() => {
        icon.classList.remove('blur');
        heroImage.classList.remove('blur'); // Remove blur from hero image
      }, 300);
    }, 300);
  };

  return (
    <div className={`App ${theme}`}>
      
      <div >
      <Monthly />
      <img src={theme === 'light' ? hero_light : hero_dark} className="App-hero" alt="hero" />

        <div className={`title-text ${theme}`}>
          <h1 className='title'>meet in the <span className={`highlight-${theme}`}>middle</span></h1>
          
        </div>   
     
        <div className={`description-text ${theme}`}>
       
        <a style={{marginBottom: '100px'}} href="https://apps.apple.com/us/app/inbetween-places/id6587549882?itscg=30200&itsct=apps_box_link&mttnsubad=6587549882/" target="_blank" rel="noopener noreferrer">
             <img src={AppStore} className={`App-logo-appstore ${theme}`} alt="logo" />
          </a>
          <p>find points of interest between you and your friends in two taps. available now on the App Store.</p>
          <img src={theme === 'light' ? logo_light : logo_dark} className="App-logo" alt="logo" />
        </div>
        <div className="footer">
          <Link to="/privacy" style={{textDecoration: "none", color: "gray"}} >
            <p>privacy</p>
          </Link>
          <Link to="/terms" style={{textDecoration: "none", color: "gray"}} >
            <p>terms</p>
          </Link>
          <Link to="/contact" style={{textDecoration: "none", color: "gray"}} >
            <p>contact</p>
          </Link>
        </div>
        <div className="footer-icons-right-corner">
        <button onClick={handleToggleTheme}>
          <img src={theme === 'light' ? moon : sun} alt="theme" className="button-img" />
        </button>
      </div>
      </div>
    </div>
  );
}

export default App;
